var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"settings-wrapper"}},[_c('v-card',{staticClass:"py-2 px-4",staticStyle:{"position":"fixed","top":"94.4px","right":"-35px","border-radius":"8px","z-index":"1"},attrs:{"id":"settings","color":"rgba(0, 0, 0, .3)","dark":"","flat":"","link":"","min-width":"100"}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(" mdi-cog ")])],1),_c('v-menu',{attrs:{"close-on-content-click":false,"activator":"#settings","bottom":"","content-class":"v-settings","left":"","nudge-left":"8","offset-x":"","origin":"top right","transition":"scale-transition"},model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',{staticClass:"text-center mb-0",attrs:{"width":"300"}},[_c('v-card-text',[_c('strong',{staticClass:"mb-3 d-inline-block"},[_vm._v("Color principal")]),_c('v-item-group',{attrs:{"mandatory":""},model:{value:(_vm.color),callback:function ($$v) {_vm.color=$$v},expression:"color"}},_vm._l((_vm.colors),function(color){return _c('v-item',{key:color,attrs:{"value":color},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-avatar',{staticClass:"v-settings__item mx-1",class:active && 'v-settings__item--active',attrs:{"color":color,"size":"25"},on:{"click":toggle}})]}}],null,true)})}),1),_c('v-divider',{staticClass:"my-4 secondary"}),_c('strong',{staticClass:"mb-3 d-inline-block"},[_vm._v("Fondo del menu")]),_c('v-item-group',{attrs:{"mandatory":""},model:{value:(_vm.gradient),callback:function ($$v) {_vm.gradient=$$v},expression:"gradient"}},_vm._l((_vm.gradients),function(scrim,index){return _c('v-item',{key:scrim,staticClass:"mx-1",attrs:{"value":index},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-avatar',{staticClass:"v-settings__item",class:active && 'v-settings__item--active',attrs:{"color":scrim,"size":"24"},on:{"click":toggle}})]}}],null,true)})}),1),_c('v-divider',{staticClass:"my-4 secondary"}),_c('v-row',{attrs:{"align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_vm._v(" Modo oscuro ")]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-switch',{staticClass:"ma-0 pa-0",attrs:{"color":"secondary","hide-details":""},model:{value:(_vm.$vuetify.theme.dark),callback:function ($$v) {_vm.$set(_vm.$vuetify.theme, "dark", $$v)},expression:"$vuetify.theme.dark"}})],1)],1),_c('v-divider',{staticClass:"my-4 secondary"}),_c('v-row',{attrs:{"align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_vm._v(" Mini menu ")]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-switch',{staticClass:"ma-0 pa-0",attrs:{"color":"secondary","hide-details":""},model:{value:(_vm.mini),callback:function ($$v) {_vm.mini=$$v},expression:"mini"}})],1)],1),_c('v-divider',{staticClass:"my-4 secondary"}),_c('v-row',{attrs:{"align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_vm._v(" Fondo de menu ")]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-switch',{staticClass:"ma-0 pa-0",attrs:{"color":"secondary","hide-details":""},model:{value:(_vm.drawerImage),callback:function ($$v) {_vm.drawerImage=$$v},expression:"drawerImage"}})],1)],1),_c('v-divider',{staticClass:"my-4 secondary"}),_c('strong',{staticClass:"mb-3 d-inline-block"},[_vm._v("Fondos")]),_c('v-card',{attrs:{"disabled":!_vm.drawerImage,"flat":""}},[_c('v-item-group',{staticClass:"d-flex justify-space-between mb-3",model:{value:(_vm.image),callback:function ($$v) {_vm.image=$$v},expression:"image"}},_vm._l((_vm.images),function(img,index){return _c('v-item',{key:img,staticClass:"mx-1",attrs:{"value":index},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-sheet',{staticClass:"d-inline-block v-settings__item",class:active && 'v-settings__item--active',on:{"click":toggle}},[_c('v-img',{attrs:{"src":img,"height":"100","width":"50"}})],1)]}}],null,true)})}),1)],1),_c('div',{staticClass:"my-12"})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }